import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useApplication } from "@ryerson/frontend.application";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { Button } from "@ryerson/frontend.button";
import { renderRichText } from "@components/Shared/model/Contentful";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { ContentSection } from "@ryerson/frontend.layout";
import { SearchInput } from "@ryerson/frontend.form-controls";
import { Media } from "@ryerson/frontend.layout";
import { Accordion, AccordionTab } from "@ryerson/frontend.expansion";
import { Icon } from "@ryerson/frontend.assets";
import { navigate } from "gatsby";
import { BreadcrumbProps, Crumb } from "@ryerson/frontend.navigation/dist/breadcrumb/Breadcrumb";
import { sanitizeInput } from "@components/Utilities/Utilities";

export type GlossaryHero = {
	title: string;
	searchPlaceholder: string;
	description: string;
};

export type GlossaryPageSetup = {
	contactLabel: string;
	contactButton: string;
	contactChat: string;
	searchResults: string;
	clearResults: string;
};

export type GlossaryPageProps = {
	content: GlossaryPageSetup;
	hero: GlossaryHero;
};

const GlossaryGroups = [
	{
		label: "A - C",
		query: ["a", "b", "c"],
	},
	{
		label: "D - F",
		query: ["d", "e", "f"],
	},
	{
		label: "G - I",
		query: ["g", "h", "i"],
	},
	{
		label: "J - L",
		query: ["j", "k", "l"],
	},
	{
		label: "M - O",
		query: ["m", "n", "o"],
	},
	{
		label: "P - R",
		query: ["p", "q", "r"],
	},
	{
		label: "S - U",
		query: ["s", "t", "u"],
	},
	{
		label: "V - Z",
		query: ["v", "w", "x", "y", "z"],
	},
];

type ContentfulNode = {
	slug: string;
	title: string;
	definition: any;
	highlightedTitle?: any;
	highlightedDefinition?: any;
	node_locale: any;
};

const GlossaryCustomBreadcrumb: Crumb[] = [
	{ label: "Home", url: "/" },
	{ label: "Metal Resources", url: "/metal-resources" },
	{ label: "Glossary", url: "/metal-resources/references/Glossary" },
];

const GlossaryBreadcrumb: React.FC = () => {
	const GlossaryBreadcrumb: BreadcrumbProps = {
		data: GlossaryCustomBreadcrumb,
		type: "tertiary",
		size: "xs",
	};
	return <Breadcrumb {...GlossaryBreadcrumb} />;
};

const Left = styled.div`
	display: inline-block;
	width: 260px;
	height: auto;
	vertical-align: top;
`;

const ContactPanel = styled.div`
	width: 100%;
	height: auto;
	padding: 20px 20px 25px 20px;
	margin-top: 10px;
	border-radius: 2px;
`;

const Filters = styled.div`
	display: block;
	width: 100%;
	height: auto;
	border-radius: 2px;
`;

const Filter = styled.div`
	display: block;
	width: 100%;
	height: 40px;
	padding-left: 20px;
	box-sizing: border-box;
	line-height: 40px;
	cursor: pointer;
`;

const Right = styled.div`
	display: inline-block;
	width: calc(100% - 270px);
	height: auto;
	margin-left: 10px;
	padding: 0px;
	border-radius: 2px;
`;

const RightContent = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding: 40px;
	box-sizing: border-box;
`;

const EndDivider = styled.hr`
	display: block;
	width: 100%;
	margin-bottom: 100px;
	margin-top: 0px;
	opacity: 0.1;
`;

const BeginningDivider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 0;
	margin-bottom: 0;
	opacity: 0.1;
`;

const GlossaryItem = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding-top: 25px;
	padding-bottom: 25px;
`;

const NegativeSpacer = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	margin-top: -50px;
`;

const AccordionContainer = styled.div`
	display: block;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
	height: auto;
`;

const MobileSpacer = styled.div`
	display: block;
	width: 100%;
	height: 35px;
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const HeroLeft = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 320px);
	height: auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				height: auto;
			}
		`;
	}}
`;

const HeroRight = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 320px;
	padding-top: 15px;
	height: auto;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				display: block;
				width: 100%;
				height: auto;
			}
		`;
	}}
`;

const SearchResults = styled.div`
	display: block;
	height: 0px;
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
`;

const SearchResultsText = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 215px;
	height: 20px;
	margin-top: 20px;
	padding: 0 31px 0 20px;
	box-sizing: border-box;
`;

const SearchResultsClear = styled.div`
	display: inline-block;
	vertical-align: top;
	line-height: 60px;
	width: 180px;
	padding: 0 0 0 30px;
`;

const SearchClearIcon = styled.div`
	display: inline-block;
	width: 24px;
	height: 24px;
	border-radius: 24px;
	text-align: center;
	line-height: 24px;
	box-sizing: border-box;
	margin-right: 12px;
`;

const ClearSearch = styled.div`
	display: inline-block;
	width: auto;
	height: auto;
	cursor: pointer;
`;

export const MakeHighlights = (highlightString: any) => {
	const uniqueArrayItems = (value: any, index: any, self: any) => {
		return self.indexOf(value) === index;
	};
	const { theme } = useTheme();
	let matches = highlightString.match(/\|\w*\|/g);
	if (matches && matches.length > 0) {
		matches = matches.filter(uniqueArrayItems);
		let newString = highlightString;
		let parts = newString.split("|");
		let out: any[] = [];
		parts.forEach((p: any, index: number) => {
			let foundHighlight = false;
			for (let i = 0; i < matches.length; i++) {
				if (p === matches[i].replaceAll("|", "")) {
					foundHighlight = true;
					out.push(
						<span
							key={index}
							css={css`
								background-color: ${theme.colors.secondary.lightYellow};
							`}
						>
							{p}
						</span>
					);
				}
			}
			if (!foundHighlight) out.push(<React.Fragment key={index}>{p}</React.Fragment>);
		});

		return <>{out}</>;
	} else {
		return highlightString;
	}
};

const GlossaryPage: React.FC<GlossaryPageProps> = ({ content, hero }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	const queryData = useStaticQuery(graphql`
		query GlossaryQuery {
			allContentfulGlossary(sort: { fields: title }) {
				edges {
					node {
						slug
						title
						company
						definition {
							raw
						}
						node_locale
					}
				}
			}
		}
	`);
	const company =
		process.env.GATSBY_COMPANY === "southernToolSteel" ? "Southern Tool Steel" : "Ryerson";
	let GlossaryItems: ContentfulNode[] = [];
	queryData.allContentfulGlossary.edges.forEach((nodes: any) => {
		if (
			nodes.node.node_locale.split("-").shift() === language &&
			nodes.node.company.indexOf(company) !== -1
		) {
			GlossaryItems.push(nodes.node);
		}
	});

	React.useEffect(() => {
		let newGlossaryItems: ContentfulNode[] = [];
		queryData.allContentfulGlossary.edges.forEach((nodes: any) => {
			if (
				nodes.node.node_locale.split("-").shift() === language &&
				nodes.node.company.indexOf(company) !== -1
			) {
				newGlossaryItems.push(nodes.node);
			}
		});
		setCurrentItems(newGlossaryItems);
	}, [language]);

	const [currentGroup, setCurrentGroup] = React.useState<string>("");
	const [currentItems, setCurrentItems] = React.useState<ContentfulNode[]>(GlossaryItems);
	const [currentGroups, setCurrentGroups] = React.useState<any[]>(GlossaryGroups);
	const [nSearchResults, setNSearchResults] = React.useState<number>(0);
	React.useEffect(() => {
		if (currentGroup.length > 0) {
			setSearchTerm("");
			let newItems: ContentfulNode[] = [];
			let newGroups: any[] = [];
			let query: any[];
			GlossaryGroups.forEach((group: any) => {
				if (group.label === currentGroup) {
					query = group.query;
					newGroups.push(group);
				}
			});
			GlossaryItems.forEach((item: ContentfulNode, index: number) => {
				if (query.includes(item.title.charAt(0).toLowerCase())) newItems.push(item);
			});
			setCurrentGroups(newGroups);
			setCurrentItems(newItems);
		} else {
			setCurrentItems(GlossaryItems);
			setCurrentGroups(GlossaryGroups);
		}
	}, [currentGroup]);

	const [searchTerm, setSearchTerm] = React.useState<string>("");

	const recursiveLook = (obj: any, term: string): boolean => {
		if (obj.content) {
			if (obj.content.length > 0) {
				for (let i = 0; i < obj.content.length; i++) {
					let child = obj.content[i];
					if (child.content) {
						return recursiveLook(child, term);
					} else if (child.value && child.value.length > 0) {
						return child.value.toLowerCase().includes(term.toLowerCase());
					} else {
						return false;
					}
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
		return false;
	};

	React.useEffect(() => {
		if (searchTerm.length > 0) {
			setCurrentGroup("");
			setTimeout(() => {
				if (searchTerm.length > 2) {
					let filteredItems: ContentfulNode[] = [];
					let filteredGroups: any[] = [];
					GlossaryItems.forEach((item: ContentfulNode, index: number) => {
						let mutableItem = Object.create(item);
						if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
							if (
								mutableItem.title.toLowerCase().includes(searchTerm.toLowerCase())
							) {
								let re = new RegExp(searchTerm, "gi");
								let match = mutableItem.title.match(re);
								let newTitle = mutableItem.title;
								if (match && match.length > 0)
									newTitle = newTitle.replaceAll(match[0], "|" + match[0] + "|");
								mutableItem.highlightedTitle = newTitle;
							}
							filteredItems.push(mutableItem);
						} else {
							if (
								mutableItem.definition.raw
									.toLowerCase()
									.includes(searchTerm.toLowerCase())
							) {
								if (
									recursiveLook(
										JSON.parse(mutableItem.definition.raw),
										searchTerm
									)
								) {
									filteredItems.push(mutableItem);
								}
							}
						}
					});
					if (filteredItems.length > 0) {
						setNSearchResults(filteredItems.length);
						GlossaryGroups.forEach((group: any, index: number) => {
							let addToGroup = false;
							filteredItems.forEach((item: ContentfulNode, index: number) => {
								if (group.query.includes(item.title.charAt(0).toLowerCase())) {
									addToGroup = true;
								}
								if (index === filteredItems.length - 1) {
									if (addToGroup === true) {
										filteredGroups.push(group);
									}
								}
							});
							if (GlossaryGroups.length - 1 === index) {
								setCurrentGroups(filteredGroups);
								setCurrentItems(filteredItems);
							}
						});
					} else {
						setCurrentItems([]);
						setCurrentGroups([]);
						setNSearchResults(0);
					}
				} else {
					setCurrentItems(GlossaryItems);
					setCurrentGroups(GlossaryGroups);
					setNSearchResults(0);
				}
			}, 1);
		} else {
			setCurrentItems(GlossaryItems);
			setCurrentGroups(GlossaryGroups);
			setNSearchResults(0);
		}
	}, [searchTerm]);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						{company === "Ryerson" ? (
							<Breadcrumb type="tertiary" size="xs" />
						) : (
							<GlossaryBreadcrumb />
						)}
					</BreadcrumbContainer>
					<HeroLeft theme={theme}>
						<Typography
							css={css`
								margin-top: 20px;
								margin-bottom: 25px;
							`}
							variant="h1"
							type="tertiary"
						>
							{hero.title}
						</Typography>
						<Typography variant="p" size="md" type="tertiary">
							{hero.description}
						</Typography>
					</HeroLeft>
					<HeroRight theme={theme}>
						<SearchInput
							shape="rounded"
							placeholder={hero.searchPlaceholder}
							size="md"
							label=""
							language={language}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(sanitizeInput(e.target.value, 50));
							}}
						/>
					</HeroRight>
				</ContentSection>
				<ContentSection type="tertiary" vPadding="0">
					<Left>
						<Filters
							css={css`
								background-color: ${theme.colors.secondary.background};
							`}
						>
							{GlossaryGroups.map((group: any, index: number) => {
								return (
									<Filter
										key={index}
										onClick={() => {
											group.label === currentGroup
												? setCurrentGroup("")
												: setCurrentGroup(group.label);
										}}
										css={css`
											background-color: ${group.label === currentGroup
												? theme.colors.primary.white
												: "transparent"};
										`}
									>
										<Typography
											color={
												group.label === currentGroup
													? theme.colors.primary.darkerGray
													: theme.colors.primary.link
											}
											variant="div"
											size="md"
											weight="bold"
											css={css`
												line-height: 40px;
											`}
										>
											{group.label}
										</Typography>
									</Filter>
								);
							})}
						</Filters>
						<ContactPanel
							css={css`
								background-color: ${theme.colors.primary.darkGray};
							`}
						>
							<Typography
								weight="bold"
								variant="h4"
								css={css`
									margin-top: 0px;
									margin-bottom: 30px;
									font-size: 20px;
									letter-spacing: -1px;
								`}
								type="tertiary"
							>
								{content.contactLabel}
							</Typography>
							<Button
								onClick={() => {
									navigate("/contact-us");
								}}
								size="sm"
								fullwidth={true}
								type="secondary"
								css={css`
									margin-bottom: 15px;
								`}
								shape="rounded"
								label={content.contactButton}
							/>
							<Button
								onClick={() => {
									if (window && (window as any).LiveChatWidget)
										(window as any).LiveChatWidget.call("maximize");
								}}
								size="sm"
								type="secondary"
								fullwidth={true}
								shape="rounded"
								label={content.contactChat}
							/>
						</ContactPanel>
					</Left>
					<Right
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<SearchResults
							css={css`
								background-color: ${theme.colors.primary.darkGray};
								height: ${nSearchResults > 0 ? "60px" : "0px"};
								transition: height 0.5s ease;
							`}
						>
							<SearchResultsText
								css={css`
									border-right: 1px solid rgba(255, 255, 255, 0.2);
								`}
							>
								<Typography
									variant="span"
									size="sm"
									color={theme.colors.primary.lightGray}
								>
									{nSearchResults + " " + content.searchResults}
									<Typography
										variant="span"
										size="sm"
										color={theme.colors.primary.white}
									>
										{" "}
										{'"' + searchTerm + '"'}
									</Typography>
								</Typography>
							</SearchResultsText>
							<SearchResultsClear>
								<ClearSearch
									onClick={() => {
										setSearchTerm("");
									}}
								>
									<SearchClearIcon
										css={css`
											border: 1px solid ${theme.colors.primary.gray};
										`}
									>
										<Icon
											size="xs"
											icon="close"
											css={css`
												cursor: pointer;
											`}
											color={theme.colors.primary.white}
										/>
									</SearchClearIcon>
									<Typography
										variant="span"
										color={theme.colors.primary.white}
										size="sm"
									>
										{content.clearResults}
									</Typography>
								</ClearSearch>
							</SearchResultsClear>
						</SearchResults>
						<RightContent>
							{currentGroups.map((group: any, index: number) => {
								return (
									<div
										key={index}
										css={css`
											display: ${currentGroup === group.label ||
											currentGroup === ""
												? "block"
												: "none"};
										`}
									>
										<Typography
											color={theme.colors.primary.darkGray}
											css={css`
												margin-top: 0px;
												margin-bottom: 40px;
											`}
											variant="h1"
										>
											{group.label}
										</Typography>
										<>
											{currentItems.map(
												(item: ContentfulNode, index: number) => {
													return (
														<React.Fragment key={index}>
															{group.query.includes(
																item.title.charAt(0).toLowerCase()
															) ? (
																<>
																	<BeginningDivider
																		css={{
																			color: theme.colors
																				.primary.darkGray,
																		}}
																	/>
																	<GlossaryItem>
																		<Typography
																			variant="h4"
																			type="primary"
																			css={css`
																				margin-top: 0px;
																				margin-bottom: 24px;
																			`}
																		>
																			{item.highlightedTitle &&
																			item.highlightedTitle
																				.length > 0
																				? MakeHighlights(
																						item.highlightedTitle
																				  )
																				: item.title}
																		</Typography>
																		<Typography
																			variant="div"
																			size="md"
																			type="primary"
																		>
																			{renderRichText(
																				item.definition.raw,
																				"primary",
																				null,
																				searchTerm
																			)}
																		</Typography>
																	</GlossaryItem>
																</>
															) : (
																<></>
															)}
														</React.Fragment>
													);
												}
											)}
										</>
										<EndDivider
											css={{
												color: theme.colors.primary.darkGray,
											}}
										/>
									</div>
								);
							})}
						</RightContent>
						<NegativeSpacer />
					</Right>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						{company === "Ryerson" ? (
							<Breadcrumb type="tertiary" size="xs" />
						) : (
							<GlossaryBreadcrumb />
						)}
					</BreadcrumbContainer>
					<HeroLeft theme={theme}>
						<Typography
							css={css`
								margin-top: 20px;
								margin-bottom: 25px;
							`}
							variant="h1"
							type="tertiary"
						>
							{hero.title}
						</Typography>
						<Typography variant="p" size="md" type="tertiary">
							{hero.description}
						</Typography>
					</HeroLeft>
					<HeroRight theme={theme}>
						<SearchInput
							shape="rounded"
							placeholder={hero.searchPlaceholder}
							size="md"
							label=""
							language={language}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
						/>
					</HeroRight>
				</ContentSection>
				<ContentSection type="primary" hPadding="0px" vPadding="0px">
					<AccordionContainer>
						<Accordion type="primary" multiple={true}>
							{currentGroups.map((group: any, index: number) => {
								return (
									<AccordionTab
										key={index}
										circled={true}
										header={group.label}
										maxHeight="5000px"
										multiple={true}
										defaultOpen={false}
									>
										<MobileSpacer />
										<>
											{currentItems.map(
												(item: ContentfulNode, index: number) => {
													return (
														<React.Fragment key={index}>
															{group.query.includes(
																item.title.charAt(0).toLowerCase()
															) ? (
																<>
																	<Typography
																		variant="h5"
																		type="primary"
																		css={css`
																			margin-top: 0px;
																			margin-bottom: 13px;
																		`}
																	>
																		{item.highlightedTitle &&
																		item.highlightedTitle
																			.length > 0
																			? MakeHighlights(
																					item.highlightedTitle
																			  )
																			: item.title}
																	</Typography>
																	<Typography
																		variant="div"
																		size="md"
																		type="primary"
																		css={css`
																			margin-bottom: 25px;
																		`}
																	>
																		{renderRichText(
																			item.definition.raw,
																			"primary",
																			null,
																			searchTerm
																		)}
																	</Typography>
																</>
															) : (
																<></>
															)}
														</React.Fragment>
													);
												}
											)}
										</>
									</AccordionTab>
								);
							})}
						</Accordion>
					</AccordionContainer>
					<ContactPanel
						css={css`
							background-color: ${theme.colors.primary.darkGray};
							display: block;
							width: 100%;
							margin-top: 30px;
							padding: 20px 20px 65px 20px;
						`}
					>
						<Typography
							weight="bold"
							variant="h4"
							css={css`
								margin-top: 0px;
								margin-bottom: 30px;
								font-size: 20px;
								letter-spacing: -1px;
							`}
							type="tertiary"
						>
							{content.contactLabel}
						</Typography>
						<Button
							onClick={() => {
								navigate("/contact-us");
							}}
							size="sm"
							fullwidth={true}
							type="secondary"
							css={css`
								margin-bottom: 15px;
							`}
							shape="rounded"
							label={content.contactButton}
						/>
						<Button
							onClick={() => {
								if (window && (window as any).LiveChatWidget)
									(window as any).LiveChatWidget.call("maximize");
							}}
							size="sm"
							type="secondary"
							fullwidth={true}
							shape="rounded"
							label={content.contactChat}
						/>
					</ContactPanel>
				</ContentSection>
			</Media>
		</>
	);
};

export default GlossaryPage;
